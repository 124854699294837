@import '../../../styles/index.scss';

// Sync with usePreviewResolution.ts
$containerHorizontailPadding: rem-calc(50);

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  container-type: inline-size;
  container-name: preview-container;
  background-color: _var(--gray-200);
  overflow: hidden;

  // Add overlay shadow
  // to make form appear hovering
  // over the preview container
  box-shadow: inset rem-calc(-15) 0 rem-calc(38) rgba(0, 0, 0, 0.2);

  .scrollBoundary {
    flex-grow: 1;
    overflow: auto;
    padding: _var(--padding-8) 0;
  }

  .preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    min-width: 100%;
    width: calc(
      #{_var(--widget-preview-width)} + #{$containerHorizontailPadding}
    );
    height: calc(#{_var(--widget-preview-height)} + #{_var(--padding-15)});

    &--collapsed {
      height: inherit;
    }

    &.-hasTallCanvas {
      justify-content: flex-start;
    }

    // Mobile preview (default)
    &__canvas {
      overflow: auto;
      border: 1px solid _var(--gray-300);

      // Align widget to the
      // left of the preview element
      // Defaults to centered
      > section {
        margin-left: 0;
      }

      &:hover,
      &--focus {
        border-color: _var(--primary-color);
      }
    }
  }

  // Add whitespace to accomodate
  // floating layout controls below
  @container preview-container (min-width: 740px) {
    .preview {
      padding-bottom: rem-calc(80);
    }
  }
}
