$primaryNavWidth: inline-rem-calc(260);
$publicNavHeight: inline-rem-calc(48);
$editorSubNavHeight: inline-rem-calc(52); // DEPRECATED
$mobilePublicNavHeight: inline-rem-calc(48);
$mobileNavHeight: inline-rem-calc(52);
$widgetNavHeight: inline-rem-calc(52);
$contentMaxWidth: inline-rem-calc(1370);
$publicSectionMaxWidth: inline-rem-calc(1218);

// SYNC with @/components/Editor/Layout/settings.ts
$editorLayoutTwoColumnMain: 960px;

$drawerIndex: 12;
$primaryNavFlyOutIndex: 11;
$subNavIndex: 10;
$tooltipZindex: 2;
